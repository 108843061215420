/* eslint-disable no-restricted-syntax */
import vSelect from 'vue-select'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import { bookingDrivingRange } from '@/api/booking-driving-range'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S,
} from '@/api/constant.js'

export default {
  name: 'SidebarCheckout',
  components: {
    vSelect,
    'confirm-dialog': ConfirmDialog,
    ShortKey,
  },
  props: [
    'bookingDetailData',
    'bookingDrId',
    'closePayment',
    'isPaymentClose',
  ],
  data() {
    return {
      selected: null,
      BookingDrDetail: [],
      BookingDetailServices: [],
      BookingDetailStatistic: [],
      Payment: [],
      ListMember: [],
      MemberPay: null,
      isCheckAllServiceInvoice: false,
      ischeckAllServicesBookDetail: false,
      isGetBookingDetailIdRBKD2D: false,
      searchOptions: {
        BookingDrCode: '',
        BDC: [],
        KeyWord: '',
      },
      fields: [
        { key: 'FirstName', label: 'Nguời thanh toán', thClass: 'checkout-payment-th' },
        { key: 'TotalAmount', label: 'Tổng', thClass: 'checkout-payment-th' },
        { key: 'ReceivedAmount', label: 'Còn thiếu', thClass: 'checkout-payment-th' },
        {
          key: 'Actions',
          label: '',
          thStyle: { width: '40px' },
          thClass: 'checkout-payment-th',
        },
      ],
      MemberPay_options: [
        { label: 'One', id: 1 },
        { label: 'Two', id: 2 },
      ],
      dataBooking: null,
      isLoading: false,
      isOpen: false,
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S],
      allChildComponentClosed: [
        {
          ComponentId: 'modal-payment-dr',
          IsActive: false,
        },
      ],
      listRemovePaymentId: null,
      dataCheckOut: null
    }
  },
  computed: {
    ALL() {
      for (const item of this.BookingDetailStatistic) {
        if (item.Title === 'ALL') {
          return item.Value
        }
      }
    },
    CHECK_OUT() {
      for (const item of this.BookingDetailStatistic) {
        if (item.Title === 'CHECK_OUT') {
          return item.Value
        }
      }
    },
    FULL_PAY() {
      for (const item of this.BookingDetailStatistic) {
        if (item.Title === 'FULL_PAY') {
          return item.Value
        }
      }
    },
    NOT_PAID_IN_FULL() {
      for (const item of this.BookingDetailStatistic) {
        if (item.Title === 'NOT_PAID_IN_FULL') {
          return item.Value
        }
      }
    },

  },
  watch: {
    isOpen(val) {
      if (val) {
        setTimeout(() => {
          this.$refs.listBDC.$refs.search.focus()
        }, 500)
      }
      this.$emit('is-open', { ComponentId: 'sidebar-checkout-dr', IsActive: val })
    },
    isPaymentClose() {
      this.allChildComponentClosed[0].IsActive = this.isPaymentClose
    },
    bookingDetailData: {
      handler(val, oldVal) {
        this.resetData()
        if (this.bookingDetailData.bookingDetailId) {
          this.getDetailBooking_RBKD2D()
        }
      },
      deep: true,
    },
    searchOptions: {
      handler(val, oldVal) {
        if (this.searchOptions.BDC.length || this.searchOptions.KeyWord || this.searchOptions.BookingDrCode) {
          this.BookingDrDetail = []
          this.BookingDetailServices = []
          this.Payment = []
          this.isCheckAllServiceInvoice = false
          this.ischeckAllServicesBookDetail = false
          this.getDetailBookingNotById_RBKD2D()
        }
      },
      deep: true,
    },
    // Khi tạo mới 1 thanh toán, tương đương với close modal payment
    closePayment() {
      this.getDetailBooking_RBKD2D()
    },
  },
  mounted() {
  },
  methods: {
    resetData() {
      const initialData = this.$options.data.call(this)
      Object.assign(this.$data, initialData)
    },
    async checkOut_ICID02() {
      const dataCheckOut = []
      // Kiểm tra checkout hàng loạt hay 1 người từ chi tiết

      this.BookingDrDetail.forEach(x => {
        if (x.Checked && x.BookingDetailStatus === 'CHECK_IN') {
          dataCheckOut.push(x.Id)
        }
      })
      this.dataCheckOut = dataCheckOut
      const listRemovePaymentId = []
      this.Payment.filter(x => x.BookingDrDetailServiceId.length === 0 && x.IsDeleted === false && x.ReceivedAmount === 0 && x.TotalAmount === 0).forEach(y => {
        listRemovePaymentId.push(y.Id)
      })
      this.listRemovePaymentId = listRemovePaymentId
      if (dataCheckOut.length) {
        if (listRemovePaymentId.length > 0) {
          this.$refs.confirmRemoveInvoiceComponent.open({
            dataConfirm: {
              title: 'Xóa hóa đơn',
              content: 'Bạn có chắc chắn xóa các hóa đơn 0đ?',
            },
          })
        } else {
          this.checkOutICID02(dataCheckOut)
        }
      } else {
        this.showToast('error', this.$t('golf_booking_please_select_person_to_check_out'))
      }
    },
    async checkOutICID02(dataCheckOut) {
      const body = {
        BookingDrDetailId: dataCheckOut,
      }
      const response = await bookingDrivingRange.api_ICID02(body)
      this.showResToast(response)
      if (response.Status == 200) {
        this.$emit('event', { type: 'after-checkout-from-sidebar-checkout' })
        if (this.searchOptions.BookingDrCode || this.searchOptions.KeyWord) {
          this.getDetailBookingNotById_RBKD2D()
        } else {
          this.getDetailBooking_RBKD2D()
        }
      }
    },
    async removeInvoice0d_api_DPM10(listPaymentId) {
      const body = {
        PaymentDrId: listPaymentId,
      }
      await bookingDrivingRange.api_DPM10(body)
    },
    async RemoveInvoice() {
      await this.removeInvoice0d_api_DPM10(this.listRemovePaymentId)
      await this.checkOutICID02(this.dataCheckOut)
    },
    // Lấy tất cả dịch vụ trong tất cả booking, hiển thị cột phải
    getAllServiceInAllBooking() {
      const BookingDetailServices = []
      // lay ra tat ca cac service, va total amount cua no
      for (const BookingDetail of this.BookingDrDetail) {
        const markArr = [] // Mảng các service đã được duyệt qua
        for (let i = 0; i < BookingDetail.BookingDrDetailService.length; i++) {
          // Nếu service không nằm trong mảng đã được duyệt thì tiếp tục
          if (markArr.includes(BookingDetail.BookingDrDetailService[i].ServiceId)) {
            continue
          }
          const temp = {}
          temp.ServiceId = BookingDetail.BookingDrDetailService[i].ServiceId
          temp.ServiceName = BookingDetail.BookingDrDetailService[i].ServiceName
          temp.TotalAmount = BookingDetail.BookingDrDetailService[i].TotalAmount // ? BookingDetail.BookingDrDetailService[i].TotalAmount : BookingDetail.BookingDrDetailService[i].Amount
          // temp.Checked = BookingDetail.BookingDrDetailService[i].Checked

          if (i != BookingDetail.BookingDrDetailService.length - 1) { // Chưa phải là phần tử cuối của mảng BookingDrDetailService
            for (let j = i + 1; j < BookingDetail.BookingDrDetailService.length; j++) {
              if (temp.ServiceId == BookingDetail.BookingDrDetailService[j].ServiceId) {
                markArr.push(temp.ServiceId)
                temp.TotalAmount += BookingDetail.BookingDrDetailService[j].TotalAmount // ? BookingDetail.BookingDrDetailService[j].TotalAmount : BookingDetail.BookingDrDetailService[j].Amount
              }
            }
          }

          if (BookingDetailServices.length == 0) {
            BookingDetailServices.push(temp)
          } else {
            for (const [index, BookingDetailService] of BookingDetailServices.entries()) {
              if (temp.ServiceId == BookingDetailService.ServiceId) {
                BookingDetailServices[index].TotalAmount += temp.TotalAmount
                break
              }
              if (index == BookingDetailServices.length - 1) {
                BookingDetailServices.push(temp)
                break
              }
            }
          }
        }
      }
      this.BookingDetailServices = BookingDetailServices
    },
    // Lấy tất cả user trong tất cả booking, dùng cho chọn người thanh toán
    getAllMemberInBooking() {
      const listMember = this.BookingDrDetail.filter((value, index, self) => index === self.findIndex(t => (
        t.MemberId === value.MemberId && t.FullName === value.FullName
      ))).map(item => ({
        id: item.MemberId,
        label: item.FullName,
        bookingDrDetailId: item.Id,
      }))

      const itemPayPerPerson = [{
        id: -99,
        label: this.$t('golf_payment_per_person'),
        bookingDrDetailId: null,
      }]
      this.ListMember = itemPayPerPerson.concat(listMember)
      if (this.ListMember.length > 1) {
        this.MemberPay = this.ListMember[1]
      } 
    },
    // Convert trạng thái booking sang text
    getStatusText(status) {
      switch (status) {
        case 'CHECK_IN':
          return 'CheckIn'
        case 'CHECK_OUT':
          return 'CheckOut'
        case 'CANCELLED':
          return 'Cancelled'
        case 'NOSHOW':
          return 'No-Show'
        default:
          return 'Booked'
      }
    },
    hideSideBar() {
      this.$root.$emit('bv::toggle::collapse', 'sidebar-checkout-dr')
      // Đóng sidebar checkout là đóng hẳn popup
      // this.$emit('event', { type: 'view-detail-booking', bookingDrId: this.bookingDrId, bookingDrDetailId: this.bookingDetailData.bookingDetailId[0] }) // Id: this.bookingDrId.Id, TeeTimeId: this.bookingDrId.TeeTimeId
    },
    // Lấy thông tin chi tiết của booking
    async getDetailBooking_RBKD2D() {
      this.isGetBookingDetailIdRBKD2D = true
      this.BookingDrDetail = []
      this.BookingDetailStatistic = []
      // bookingDetailData.bookingDetailId danh sách các bookingId, gọi từng API lấy chi tiết của booking.

      const data = {
        BookingDrDetailId: this.bookingDetailData.bookingDetailId,
      }
      await bookingDrivingRange.api_RBKD2D(data).then(response => {
        if (response.Status === '200') {
          // Gộp BookingDrDetail
          this.BookingDrDetail = [...this.BookingDrDetail, ...response?.Data?.BookingDrDetail]
          this.BookingDetailStatistic = [...this.BookingDetailStatistic, ...response?.Data?.BookingDetailStatistic]
          // Thêm key Checked vào list các dịch vụ
          for (const bookingDetail of this.BookingDrDetail) {
            for (const bookingDetailService of bookingDetail.BookingDrDetailService) {
              bookingDetailService.Checked = false
            }
          }
          // Lấy danh sách hoá đơn
          this.getTempInvoice_RPM03()
          // Lấy tất cả user trong booking để chọn người thanh toán
          this.getAllMemberInBooking()
          // Lấy danh sách tất cả dịch vụ nằm trong booking
          this.getAllServiceInAllBooking()
        } else {
          this.showResToast(response)
        }
      })
    },
    // Tìm kiếm thông tin chi tiết của booking
    async getDetailBookingNotById_RBKD2D() {
      this.isGetBookingDetailIdRBKD2D = false
      this.BookingDrDetail = []
      this.BookingDetailStatistic = []
      await bookingDrivingRange.api_RBKD2D(this.searchOptions).then(response => {
        if (response.Status === '200') {
          // Gộp BookingDrDetail
          this.BookingDrDetail = [...this.BookingDrDetail, ...response?.Data?.BookingDrDetail]
          this.BookingDetailStatistic = [...this.BookingDetailStatistic, ...response?.Data?.BookingDetailStatistic]
          // Thêm key Checked vào list các dịch vụ
          for (const bookingDetail of this.BookingDrDetail) {
            for (const bookingDetailService of bookingDetail.BookingDrDetailService) {
              bookingDetailService.Checked = false
            }
          }
          // Lấy danh sách hoá đơn
          this.getTempInvoice_RPM03()
          // Lấy tất cả user trong booking để chọn người thanh toán
          this.getAllMemberInBooking()
          // Lấy danh sách tất cả dịch vụ nằm trong booking
          this.getAllServiceInAllBooking()

          // this.getRPM01()
          // this.getAllServiceInAllBooking()
          // this.getAllMemberInBooking()
          // // Thêm key Checked vào list các dịch vụ
          // for (const bookingDetail of this.BookingDrDetail) {
          //   for (const bookingDetailService of bookingDetail.BookingDetailServices) {
          //     bookingDetailService.checked = false
          //   }
          // }
        }
      })
    },
    // Mở popup tạo thanh toán hoá đơn
    openModalPayment(id) {
      this.$emit('event', { type: 'open-modal-payment', paymentId: id })
    },
    // Mở popup xoá hoá đơn
    openModalDeletePayment(id) {
      this.$emit('event', { type: 'open-modal-delete-payment', paymentId: id })
    },
    async getTempInvoice_RPM03() {
      const BookingDrDetailId = []
      this.BookingDrDetail.forEach(item => {
        BookingDrDetailId.push(item.Id)
      })
      if (BookingDrDetailId.length > 0) {
        const data = {
          BookingDrDetailId,
        }
        await bookingDrivingRange.api_RPM03(data).then(response => {
          if (response.Status === '200') {
            if (this.BookingDrDetail.length === 1) {
              if (response.Data.PaymentDr.length == 0) {
                this.isCheckAllServiceInvoice = true
                this.allCheckBoxInvoice()
              } else {
                let listServiceInInvoiceTemp = []
                response.Data.PaymentDr.forEach(payment => {
                  listServiceInInvoiceTemp = [...listServiceInInvoiceTemp, ...payment.BookingDrDetailServiceId]
                })
                this.BookingDrDetail.forEach(bookingDetail => {
                  bookingDetail.BookingDrDetailService.forEach(BookingDetailServices => {
                    if (listServiceInInvoiceTemp.find(x => x === BookingDetailServices.Id)) {
                      BookingDetailServices.Checked = false
                    } else {
                      BookingDetailServices.Checked = true
                    }
                  })
                })
              }
            }
            this.Payment = response?.Data?.PaymentDr.filter(x => x.IsDeleted === false)
          }
        })
      }
    },
    singleCheckBoxInvoice(ServiceId, isCheck) {
      this.BookingDrDetail.forEach(x => {
        x.BookingDrDetailService.forEach(y => {
          if (y.ServiceId === ServiceId) {
            y.Checked = isCheck
          }
        })
      })
    },
    allCheckBoxInvoice() {
      // let tmp = [...this.BookingDrDetail]
      this.BookingDrDetail.forEach(x => {
        x.Checked = this.isCheckAllServiceInvoice
        x.BookingDrDetailService.forEach(y => {
          y.Checked = this.isCheckAllServiceInvoice
        })
      })
      // this.BookingDrDetail = Object.assign([], tmp)

      this.BookingDetailServices.forEach(x => {
        x.Checked = this.isCheckAllServiceInvoice
      })

      // tmp = [...this.BookingDetailServices]
      // for (const bookingDetailService of tmp) {
      //   bookingDetailService.checked = this.isCheckAllServiceInvoice
      // }
      // this.BookingDetailServices = Object.assign([], tmp)
    },
    checkAllServicesBookDetail(BookingDetailId) {
      const tmp = [...this.BookingDrDetail]
      for (const bookingDetail of tmp) {
        if (bookingDetail.Id == BookingDetailId) {
          for (const bookingDetailService of bookingDetail.BookingDrDetailService) {
            bookingDetailService.Checked = bookingDetail.Checked
          }
          break
        }
      }
      this.BookingDrDetail = Object.assign([], tmp)
    },
    // Tạo hoá đơn tạm tính
    async createTemporaryInvoice_IMP03() {
      if (!this.MemberPay) { return this.showToast('error', this.$t('golf_sidebar_checkout_please_select_person_payment')) }
      const PaymentDr = {}
      let isNotServiceEmpty = false
      PaymentDr.MemberId = this.MemberPay.id
      PaymentDr.BookingDrDetailId = this.MemberPay.bookingDrDetailId
      PaymentDr.Status = 'Temporary'
      PaymentDr.PaymentDrBookingDr = []
      PaymentDr.PaymentDrDiscount = []
      PaymentDr.PaymentDrDetail = []
      for (const bookingDetail of this.BookingDrDetail) {
        const PaymentDrBookingDr = {}
        PaymentDrBookingDr.BookingDrId = bookingDetail.BookingDrId
        PaymentDrBookingDr.PaymentDrBookingDrDetail = []
        const PaymentDrBookingDrDetail = {}

        PaymentDrBookingDrDetail.PaymentDrBookingDrDetailService = []
        for (const bookingDetailService of bookingDetail.BookingDrDetailService) {
          if (bookingDetailService.Checked) {
            const PaymentBookingDetailService = {
              ServiceId: bookingDetailService.ServiceId,
              UnitPrice: bookingDetailService.UnitPrice,
              Quantity: bookingDetailService.Quantity,
              Amount: bookingDetailService.Amount,
              TotalAmount: bookingDetailService.TotalAmount,
              RateId: bookingDetailService.RateId,
              PaymentDrBookingDrDetailServiceDiscount: [],
              ServiceType: bookingDetailService.ServiceType,
              BookingDrDetailServiceId: bookingDetailService.Id,
            }
            PaymentDrBookingDrDetail.PaymentDrBookingDrDetailService.push(PaymentBookingDetailService)
          }
        }
        bookingDetail.PaymentDr.forEach(element => {
          for (const pay of element.PaymentDrDetail) {
            if (pay.Checked) {
              PaymentDr.PaymentDrDetail.push({
                Id: pay.Id,
              })
            }
          }
        })
        if (!PaymentDrBookingDrDetail.PaymentDrBookingDrDetailService.length) {
          isNotServiceEmpty = isNotServiceEmpty || false
        } else {
          PaymentDrBookingDrDetail.BookingDrDetailId = bookingDetail.Id
          isNotServiceEmpty = isNotServiceEmpty || true
        }

        if (PaymentDrBookingDrDetail.BookingDrDetailId) {
          PaymentDrBookingDr.PaymentDrBookingDrDetail.push(PaymentDrBookingDrDetail)
          PaymentDr.PaymentDrBookingDr.push(PaymentDrBookingDr)
        }
      }

      const body = {
        PaymentDr,
      }
      if (!isNotServiceEmpty) {
        return this.showToast('error', this.$t('golf_sidebar_checkout_please_select_sevice'))
      }

      this.isLoading = true
      await bookingDrivingRange.api_IPM03(body).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          if (this.isGetBookingDetailIdRBKD2D) { this.getDetailBooking_RBKD2D() } else { this.getDetailBookingNotById_RBKD2D() }
          this.getTempInvoice_RPM03()
        }
      })
      this.isLoading = false
    },
    getBookingARest(Options) {
      return Options.TotalAmount - Options.ReceivedAmount
    },
    // Tổng tiền dịch vụ đã mua
    getTotalAmount(BookingDetailServices) {
      let total = 0
      BookingDetailServices.forEach(item => {
        total += item.TotalAmount ? item.TotalAmount : 0 // item.Amount
      })
      return total
    },
    // Tổng tiền đã thanh toán
    getTotalPaymentReceivedAmount() {
      let total = 0
      for (const payment of this.Payment) {
        total += payment.ReceivedAmount
      }
      return total
    },
    // Tô màu dịch vụ nằm trong hoá đơn
    fillColor(id) {
      let color = ''
      if (this.Payment.length > 0 && id) {
        this.Payment.forEach(e => {
          if (e.BookingDrDetailServiceId.includes(id)) {
            color = e.Color
          }
        })
      }
      return color
    },
    fillColorPayment(id) {
      let color = ''
      if (this.Payment.length > 0 && id) {
        if (this.Payment.filter(x => x.Id == id).length) {
          color = this.Payment.find(x => x.Id == id).Color
        }
      }
      return color
    },
    getARest(BookingDrDetailService, PaymentDr) {
      let total = 0
      BookingDrDetailService.forEach(item => {
        total += item.TotalAmount ? item.TotalAmount : 0 // item.Amount
      })

      let paid = 0

      PaymentDr.forEach(x => {
        x.PaymentDrDetail.forEach(y => {
          paid += y.Amount ? y.Amount : 0
        })
      })
      return total - paid
    },
    OpenRIV01(paymentId) {
      const routeData = this.$router.resolve({ name: 'bookingdrinvoice', params: { id: paymentId } })
      window.open(routeData.href, '_blank')
    },
    async moveInvoice(paymentId) {
      // if (!this.MemberPay) { return this.showToast('error', this.$t('golf_sidebar_checkout_please_select_person_payment')) }
      const PaymentDr = {}
      let isNotServiceEmpty = false
      // PaymentDr.MemberId = this.MemberPay.id
      // PaymentDr.BookingDrDetailId = this.MemberPay.bookingDrDetailId
      PaymentDr.Status = 'Temporary'
      PaymentDr.PaymentDrBookingDr = []
      PaymentDr.PaymentDrDiscount = []
      PaymentDr.PaymentDrDetail = []
      for (const bookingDetail of this.BookingDrDetail) {
        const PaymentDrBookingDr = {}
        PaymentDrBookingDr.BookingDrId = bookingDetail.BookingDrId
        PaymentDrBookingDr.PaymentDrBookingDrDetail = []
        const PaymentDrBookingDrDetail = {}

        PaymentDrBookingDrDetail.PaymentDrBookingDrDetailService = []
        for (const bookingDetailService of bookingDetail.BookingDrDetailService) {
          if (bookingDetailService.Checked) {
            const PaymentBookingDetailService = {
              ServiceId: bookingDetailService.ServiceId,
              UnitPrice: bookingDetailService.UnitPrice,
              Quantity: bookingDetailService.Quantity,
              Amount: bookingDetailService.Amount,
              TotalAmount: bookingDetailService.TotalAmount,
              RateId: bookingDetailService.RateId,
              PaymentDrBookingDrDetailServiceDiscount: [],
              ServiceType: bookingDetailService.ServiceType,
              BookingDrDetailServiceId: bookingDetailService.Id,
            }
            PaymentDrBookingDrDetail.PaymentDrBookingDrDetailService.push(PaymentBookingDetailService)
          }
        }
        bookingDetail.PaymentDr.forEach(element => {
          for (const pay of element.PaymentDrDetail) {
            if (pay.Checked) {
              PaymentDr.PaymentDrDetail.push({
                Id: pay.Id,
              })
            }
          }
        })
        if (!PaymentDrBookingDrDetail.PaymentDrBookingDrDetailService.length) {
          isNotServiceEmpty = isNotServiceEmpty || false
        } else {
          PaymentDrBookingDrDetail.BookingDrDetailId = bookingDetail.Id
          isNotServiceEmpty = isNotServiceEmpty || true
        }

        if (PaymentDrBookingDrDetail.BookingDrDetailId) {
          PaymentDrBookingDr.PaymentDrBookingDrDetail.push(PaymentDrBookingDrDetail)
          PaymentDr.PaymentDrBookingDr.push(PaymentDrBookingDr)
        }
      }

      const body = {}
      body.PaymentDr = PaymentDr
      body.PaymentDr.Id = paymentId

      // PaymentDr.PaymentDr.Id = paymentId
      // const body = {
      //   PaymentDr,
      // }
      if (!isNotServiceEmpty && PaymentDr.PaymentDrDetail.length < 1) {
        return this.showToast('error', this.$t('golf_sidebar_checkout_please_select_sevice'))
      }

      this.isLoading = true
      await bookingDrivingRange.api_UPM03(body).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          if (this.isGetBookingDetailIdRBKD2D) { this.getDetailBooking_RBKD2D() } else { this.getDetailBookingNotById_RBKD2D() }
          this.getTempInvoice_RPM03()
        }
      })
      this.isLoading = false
    },
    call_api_UPM04(data) {
      const body = {
        PaymentDr: {
          Id: data.Id,
        },
      }
      bookingDrivingRange.api_UPM04(body).then(async response => {
        if (response.Status === '200') {
          this.getTempInvoice_RPM03()
          this.showResToast(response)
          this.$emit('event', { type: 'after-issue-invoice' })
        } else {
          this.showResToast(response)
        }
      })
    },
    async changeStatusToTempInvoice(data) {
      await bookingDrivingRange.api_UPM08({ PaymentDrId: [data.Id] }).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.getTempInvoice_RPM03()
        }
      })
    },
    async removeService() {
      let isChecklistServiceInInvoice = true
      const body = {
        BookingDrDetailServiceId: [],
      }
      this.BookingDrDetail.forEach(x => {
        x.BookingDrDetailService.forEach(y => {
          if (y.Checked) {
            body.BookingDrDetailServiceId.push(y.Id)
          }
        })
      })

      const listServiceInInvoice = []
      this.Payment.forEach(x => {
        x.BookingDrDetailServiceId.forEach(y => {
          listServiceInInvoice.push(y)
        })
      })

      body.BookingDrDetailServiceId.forEach(x => {
        if (!listServiceInInvoice.includes(x)) {
          isChecklistServiceInInvoice = false
        }
      })

      if (body.BookingDrDetailServiceId.length > 0 && isChecklistServiceInInvoice) {
        await bookingDrivingRange.api_DPM08(body).then(res => {
          this.showResToast(res)
          if (res.Status == '200') {
            if (this.searchOptions.BookingDrCode || this.searchOptions.KeyWord) {
              this.getDetailBookingNotById_RBKD2D()
            } else {
              this.getDetailBooking_RBKD2D()
            }
          }
        })
      } else if (!isChecklistServiceInInvoice) {
        this.showToast('error', this.$t('golf_sidebar_checkout_service_not_in_invoice'))
      } else {
        this.showToast('error', this.$t('golf_sidebar_checkout_please_select_sevice'))
      }
    },
    onTriggeredEventHandler(payload) {
      // if (payload.key === F4) {
      //   this.hideSideBar()
      // }
      if (payload.evt.ctrlKey && payload.key === F) {
        this.$refs.listBDC.$refs.search.focus()
      }
      if (payload.evt.ctrlKey && payload.key === A) {
        this.isCheckAllServiceInvoice = !this.isCheckAllServiceInvoice
        this.allCheckBoxInvoice()
      }
      if (payload.evt.ctrlKey && payload.key === S) {
        this.createTemporaryInvoice_IMP03()
      }
    },
  },
}

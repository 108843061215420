<template>

  <b-modal
    id="modal-update-booking-dr-info"
    v-model="isOpen"
    size="md"
    centered
    ok-only
    :ok-title="$t('golf_common_update')"
    ok-variant="primary"
    @ok="updateInfoGroup_UBKD01"
  >
    <template #modal-header="{ close }">
      <h4 class="mb-0 text-primary">
        {{ $t('golf_common_edit_booking_info') }}
      </h4>
      <ez-icon
        icon="ezGolf-icon-x"
        class="cursor-pointer"
        size="24"
        color="#114A9F"
        @click="close"
      />
    </template>
    <b-card-body>
      <b-form>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-form-input
                v-model="BookingDR.GroupName"
                :placeholder="$t('golf_boongking_group_name')"
                maxlength="100"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <div class="text-right">
              {{ $t('golf_boongking_dr_booking_code') }}:
              <span class="text-primary font-weight-bold">#{{ data.BookingCode }}</span>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group>
              <v-select
                v-model="BookingDR.ClientId"
                :options="listCompany"
                label="ClientName"
                :reduce="ClientName => ClientName.Id"
                :placeholder="$t('golf_boongking_client_name')"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <v-select
                v-model="BookingDR.SourceId"
                :options="listSource"
                label="SourceName"
                :reduce="SourceName => SourceName.Id"
                :placeholder="$t('golf_boongking_source_name')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
              <b-form-group>
                <v-select
                  v-model="BookingDR.SaleId"
                  class="custom-select-bdc"
                  :options="listUserOfSale"
                  :placeholder="$t('golf_boongking_sale_man')"
                  label="label"
                  :clearable="true"
                  :reduce="label => label.id"
                />
              </b-form-group>
            </b-col>
          </b-row>
        <b-row>
          <b-col md="12">
            <b-form-group>
              <b-form-textarea
                v-model="BookingDR.RepresentativeNote"
                :placeholder="$t('golf_booking_note')"
                rows="3"
                no-resize
                maxlength="250"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-modal>

</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { bookingDrivingRange } from '@/api/booking-driving-range'
import { commonServices } from '@/api/common-services'

export default {
  name: 'UpdateGroup',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  props: ['data','listUserOfSale'],
  data() {
    return {
      BookingDR: {
        Id: null,
        GroupName: null,
        ClientId: null,
        SourceId: null,
        RepresentativeNote: null,
        SaleId: null
      },
      listSource: [],
      listCompany: [],
      isLoading: true,
      isOpen: false,
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.BookingDR = {
          Id: this.data.Id,
          GroupName: this.data.GroupName,
          ClientId: this.data.ClientId,
          SourceId: this.data.SourceId,
          RepresentativeNote: this.data.RepresentativeNote,
          SaleId: this.data.SaleId
        }
      }
    },
  },
  async mounted() {
    this.isLoading = true
    this.doubleRaf(async () => {
      await this.getListSource_RSO01()
      await this.getListCompany_RCL01()
      this.isLoading = false
    })
  },
  methods: {
    doubleRaf(callback) {
      requestAnimationFrame(() => {
        requestAnimationFrame(callback)
      })
    },
    async updateInfoGroup_UBKD01() {
      this.BookingDR.Id = this.data.Id
      const body = {
        BookingDR: this.BookingDR,
      }

      const response = await bookingDrivingRange.api_UBKD01(body)
      this.showResToast(response)
      if (response.Status == 200) {
        this.$emit('event', { type: 'after-update-group-info' })
      }
    },
    async getListSource_RSO01() {
      const response = await commonServices.getListSource({})
      if (response.Status === '200') {
        this.listSource = response?.Data?.Source
      } else {
        this.showResToast(response)
      }
    },
    async getListCompany_RCL01() {
      const response = await commonServices.getCompany({})
      if (response.Status === '200') {
        this.listCompany = response?.Data?.Client
      } else {
        this.showResToast(response)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>

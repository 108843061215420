import { bookingDrivingRange } from '@/api/booking-driving-range'
import {F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F} from '@/api/constant.js'
import ShortKey from '@/views/modules/ultils/ShortKey'
export default {
  name: 'AddNewBooking',
  components: {ShortKey},
  props: {
    listDrivingRange: {
      type: Array,
      required: false,
    },
    bookingDrId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      currentLang: this.$i18n.locale,
      isOpen: false,
      dateSelected: this.dayjs().utc().format(),
      listDrivingRangeField: [
        {
          key: 'DrivingRangeName',
          label: 'Sân tập', // $t('golf_booking_driving_range'),
        },
        {
          key: 'Inventory',
          label: 'Số người', // $t('golf_booking_driving_range_number_of_play'),

        },
      ],
      drivingRangeSelected: [],
      reload: false,
      disableAddNewButton: false,
      ShortCutKey : [F1,F2,F3,F4,F5,F6,F7,F8,F9,F10,F11,F12,A,F],
      allChildComponentClosed: []
    }
  },
  watch: {
    dateSelected() {
      this.reload = true
      this.listDrivingRange.forEach(x => {
        x.Quantity = 0
      })
      this.reload = false
    },
    isOpen(val) {
      this.resetSidebar()
      this.$emit('is-open', {ComponentId: "add-new-driving-range-booking",IsActive:val});
    },
  },
  methods: {
    async resetSidebar() {
      this.reload = true
      this.dateSelected = this.dayjs().utc().format()
      this.drivingRangeSelected = []

      await this.listDrivingRange.forEach(x => {
        x.Quantity = 0
      })
      this.reload = false
    },
    async resetQuantity() {
      this.reload = true
      await this.listDrivingRange.forEach(x => {
        x.Quantity = 0
      })
      this.reload = false
    },
    open() {
      this.isOpen = true
      this.dateSelected = this.dayjs().utc().format()
      this.drivingRangeSelected = []

      this.listDrivingRange.forEach(x => {
        x.Quantity = 0
      })
    },
    updateDrivingRangeSelected(value, drivingRangeId) {
      const temp = {
        date: this.dateSelected,
        drivingRange: [{
          Id: drivingRangeId,
          value,
        }],
      }
      // Check có cùng ngày không
      if (this.drivingRangeSelected.filter(x => this.dayjs(x.date).diff(this.dayjs(this.dateSelected)) == 0).length > 0) {
        if (this.drivingRangeSelected.find(x => this.dayjs(x.date).diff(this.dayjs(this.dateSelected)) == 0).drivingRange.filter(y => y.Id == drivingRangeId).length > 0) {
          this.drivingRangeSelected.find(x => this.dayjs(x.date).diff(this.dayjs(this.dateSelected)) == 0).drivingRange.find(y => y.Id == drivingRangeId).value = value
        } else {
          this.drivingRangeSelected.find(x => this.dayjs(x.date).diff(this.dayjs(this.dateSelected)) == 0).drivingRange.push({
            Id: drivingRangeId,
            value,
          })
        }
      } else {
        this.drivingRangeSelected.push(temp)
      }
    },
    totalPerson() {
      let total = 0
      this.drivingRangeSelected.forEach(x => {
        x.drivingRange.forEach(y => {
          total += y.value
        })
      })
      return total
    },
    removeItem(i, j) {
      this.drivingRangeSelected[i].drivingRange.splice(j, 1)

      // Nếu xoá hết sân trong ngày thì xoá cả ngày
      if (this.drivingRangeSelected[i].drivingRange.length == 0) {
        this.drivingRangeSelected.splice(i, 1)
      }
    },
    async createBoooking_RBKD01_AC1() {
      this.disableAddNewButton = true
      // Nếu là tạo booking thêm khách
      if (this.bookingDrId) {
        const body = {
          BookingDR: {
            Id: this.bookingDrId,
            BookingDRInfo: [],
          },
        }

        this.drivingRangeSelected.forEach(x => {
          const temp = []
          x.drivingRange.forEach(y => {
            if (parseInt(y.value)) {
              temp.push({
                CourseId: y.Id,
                NumberOfGolfer: parseInt(y.value),
              })
            }
          })

          body.BookingDR.BookingDRInfo.push({
            BookingInfoDate: x.date,
            GolfClassId: null,
            ClientId: null,
            RateId: null,
            BookingDRInfoCourse: temp,
          })
        })

        await bookingDrivingRange
          .api_IBKD02(body)
          .then(res => {
            if (res.Status == '200') {
              this.showResToast(res)
              this.isOpen = false
              // this.afterAddNewBookingDrivingRange(res)

              this.$emit('event', { bookingDrId: this.bookingDrId, type: 'after-add-more-customer-from-detail-booking' })
            } else {
              this.showResToast(res)
            }
          })

      // Nếu là tạo booking mới
      } else {
        const body = {
          BookingDR: {
            BookingDetailID: null,
            BookingDate: this.dayjs().toDate(),
            BookingDRInfo: [],
          },
        }

        this.drivingRangeSelected.forEach(x => {
          const temp = []
          x.drivingRange.forEach(y => {
            if (parseInt(y.value)) {
              temp.push({
                CourseId: y.Id,
                NumberOfGolfer: parseInt(y.value),
              })
            }
          })

          body.BookingDR.BookingDRInfo.push({
            BookingInfoDate: x.date,
            GolfClassId: null,
            ClientId: null,
            RateId: null,
            BookingDRInfoCourse: temp,
          })
        })

        await bookingDrivingRange
          .createBookingDrivingRange(body)
          .then(res => {
            if (res.Status == '200') {
              this.showResToast(res)
              this.isOpen = false
              // this.afterAddNewBookingDrivingRange(res)

              this.$emit('event', { bookingDrId: res.Data.BookingDR.Id, type: 'after-add-new-booking' })
            } else {
              this.showResToast(res)
            }
          })
      }
      this.disableAddNewButton = false
    },
    hideSidebar() {
      // Đóng sidebar thêm mới dịch vụ
      this.$root.$emit('bv::toggle::collapse', 'add-new-driving-range-booking')

      // Nếu là thêm mới khách (bookingDrId) thì mở lại sidebar chi tiết
      if (this.bookingDrId) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking-driving-range')
      }
    },
    onTriggeredEventHandler(payload) {    
      // if (payload.key === F4) {
      //   this.hideSidebar()
      // } 
    },
  },
}

<template>

  <b-modal
    id="modal-cancel-booking-detail-dr"
    v-model="isOpen"
    size="md"
    centered
    ok-only
    :ok-title="$t('golf_common_confirm')"
    ok-variant="danger"
    @ok="cancelDetailBooking_DBKD02"
  >
    <template #modal-header="{ close }">
      <h4 class="mb-0 text-danger">
        {{ $t('golf_booking_cancel_booking') }}
      </h4>
      <ez-icon
        icon="ezGolf-icon-x"
        class="cursor-pointer"
        size="24"
        color="#EA5455"
        @click="close"
      />
    </template>
    <b-card-body>
      <b-form>
        <div
          v-for="item in data"
          :key="item.Id"
          class="border-bottom pb-1 mb-1"
        >
          <b-row>
            <b-col md="6">
              {{ $t('golf_common_name') }}:
              <span class="text-primary font-weight-bold">{{ item.Member.FullName }}</span>
            </b-col>
            <b-col md="6">
              {{ $t('golf_common_date_play') }}:
              <span class="text-primary font-weight-bold">{{ convertUTC(item.OpenDate, 'DD/MM/YYYY HH:mm') }}</span>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              {{ $t('golf_common_email') }}:
              <span class="text-primary font-weight-bold">{{ item.Member.Email }}</span>
            </b-col>
            <b-col md="6">
              {{ $t('golf_common_phone') }}:
              <span class="text-primary font-weight-bold">{{ item.Member.PhoneNumber }}</span>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col md="12">
            <b-form-group :label="$t('golf_common_reason_cancel_booking') + ':'">
              <b-form-textarea
                v-model="reason"
                :placeholder="$t('golf_common_reason_cancel_booking')"
                rows="3"
                no-resize
                maxlength="250"
              />
            </b-form-group>
            <b-form-group>
              <b-form-checkbox v-model="sendMail">
                <p class="mb-0">
                  {{ $t('golf_common_send_email_to_customer') }}
                </p>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card-body>
  </b-modal>
</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { bookingDrivingRange } from '@/api/booking-driving-range'

export default {
  name: 'CancelBookingDetail',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: ['data'],
  data() {
    return {
      reason: '',
      sendMail: false,
      isOpen: false,
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        this.reason = ''
        this.sendMail = false
      }
    },
  },

  methods: {
    async cancelDetailBooking_DBKD02() {
      const ids = this.data.map(x => x.Id)
      const body = {
        BookingDrDetailId: ids,
        SendMail: this.sendMail,
        Reason: this.reason,
      }

      const response = await bookingDrivingRange.api_DBKD02(body)
      this.showResToast(response)
      if (response.Status == 200) {
        this.$emit('event', { type: 'after-cancel-booking-detail' })
      }
    },
  },
}
</script>

<style lang="scss">
#modal-cancel-booking-detail-dr {
  .modal-header {
    background: rgba(234, 84, 85, 0.12) !important;
  }
}
</style>

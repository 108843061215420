<template>
  <div>
    <b-modal
      id="modal-delete-payment-dr"
      size="sm"
      centered
      cancel-variant="none"
      :ok-title="$t('golf_common_delete')"
      :cancel-title="$t('golf_common_back')"
      ok-variant="primary"
      @ok="deletePayment"
    >
      <template #modal-header="{ close }">
        <h4 class="mb-0 text-primary">
          {{ $t('golf_modal_delete_payment') }}
        </h4>
        <ez-icon
          icon="ezGolf-icon-x"
          class="cursor-pointer"
          size="24"
          color="#114A9F"
          @click="close"
        />
      </template>

      <b-card-body>
        <p class="mb-0">
          {{ $t('golf_modal_delete_payment_desc') }}
        </p>
      </b-card-body>
    </b-modal>
  </div>
</template>

<script>

import { bookingDrivingRange } from '@/api/booking-driving-range'

export default {
  components: {},
  props: [
    'paymentId',
  ],
  methods: {
    hideModal() {
      this.$bvModal.hide('modal-delete-payment-dr')
    },
    async deletePayment(bvModalEvent) {
      bvModalEvent.preventDefault()
      const data = {
        PaymentDr: {
          Id: this.paymentId,
        },
      }
      await bookingDrivingRange.api_DPM03(data).then(async response => {
        this.showResToast(response)
        if (response.Status === '200') {
          this.$emit('event', { type: 'after-delete-payment', response })
          this.$nextTick(() => {
            this.$bvModal.hide('modal-delete-payment-dr')
          })
        } else {
          this.$nextTick(() => {
            this.$bvModal.hide('modal-delete-payment-dr')
          })
        }
      }).catch(error => {
        this.showToast('error', this.$t('golf_common_error'))
        this.$nextTick(() => {
          this.$bvModal.hide('modal-delete-payment-dr')
        })
      })
    },
  },
}

</script>

<style lang="scss">
::v-deep {
  .modal-content {
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    width: 400px;
  }
}

.color-red {
  color: red;
}

.color-warning {
  color: #114A9F;
}

.title-modal {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  color: #114A9F;
  line-height: 21px;
}

.card-body {
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #5D5F65;
  }
}

.content-body-modal {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}
</style>

import vSelect from 'vue-select'
import { bookingDrivingRange } from '@/api/booking-driving-range.js'
import { bookingPrint } from '@/api/booking-print'
import VueQrcode from 'vue-qrcode'
import UserAvatar from '@/views/modules/booking/UserAvatar'
import SidebarContent from '@/views/modules/members/SidebarContent.vue'
import { member_params } from '@/views/modules/booking/params/member'
import { members } from '@/api/members'
import History from '@/views/modules/booking-driving-range/modal/History.vue'
import { commonServices } from '@/api/common-services'
import ShortKey from '@/views/modules/ultils/ShortKey'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, P,
} from '@/api/constant.js'
import CheckIn from '../modal/CheckIn.vue'
import CancelBooking from '../modal/CancelBooking.vue'
import CancelBookingDetail from '../modal/CancelBookingDetail.vue'
import UpdateGroup from '../modal/UpdateGroup.vue'
import GolfService from '../AddGolfService/AddGolfService.vue'

export default {
  name: 'SidebarDetailBooking',
  components: {
    UpdateGroup,
    CancelBooking,
    CancelBookingDetail,
    SidebarContent,
    UserAvatar,
    CheckIn,
    vSelect,
    GolfService,
    ShortKey,
    VueQrcode,
    History
  },
  props: [
    'bookingDrId',
    'bookingDrDetailId',
    'listDrivingRange',
    'listGolfClass',
    'quickViewListBdc',
    'listUserOfSale',
    'isRequriedSale'
  ],
  data() {
    return {
      mode: 'info',
      searchForm2A: {
        keyWord: null,
        bookingCode: null,
        openDate: this.getUTC('start', 'day'),
        groupName: null,
        listBDC: [],
        listLocker: [],
        listBagtag: []
      },
      groupName: {
        selected: null,
        list: [],
      },
      dataDetail2A: [],
      dataDetail2B: {
        GroupName: '',
        ClientName: '',
        SourceId: '',
        BagtagCode: '',
        TotalNumberOfGolfer: 0,
        ClientId: '',
        TeeName: '',
        RepresentativeNote: '',
        BookingDrDetail: {
          Member: {
            FullName: '',
            FirstName: '',
            BookingCode: '',
            LastName: '',
            MemberTypeId: '',
            Email: '',
            PhoneNumber: '',
            GolfClassName: '',
            Avatar: '',
          },
          MemberId: '',
          BookingDetailStatus: '',
        },
      },
      countries: [],
      companies: [],
      cardType: [],
      isCreateMember: true,
      Member: member_params,
      fields: ['age'],
      items: [{ age: 40, dataUser: 1 }],
      dataCheckIn: [],
      dataCancelBookingDetail: [],
      detailBookingDrId: null,
      bookingCode: [],
      canShowAddService: false,
      checkedAll: false,
      isRemove: false,
      addMember: {
        FullName: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        Email: null,
        SexCode: 'SEX_M',
        CardCode: null,
      },
      listSex: [],
      currentLang: this.$i18n.locale,
      listSexIcon: [
        {
          value: 'SEX_M',
          icon: 'ezGolf-icon-male',
        },
        {
          value: 'SEX_F',
          icon: 'ezGolf-icon-female',
        },
        {
          value: 'SEX_LGBT',
          icon: 'ezGolf-icon-other-gender',
        },
      ],
      listMember: [],
      isLoading: false,
      dataQuickUpdate: {
        Id: null,
        BagtagCode: null,
        LockerCode: null,
        OpenDate: null,
        GolfClassId: null,
        RepresentativeNote: null,
        BookingDrDetailCourse: [],
      },
      isOpen: false,
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F, S, P],
      allChildComponentClosed: [
        {
          ComponentId: 'modal-checkin-dr',
          IsActive: false,
        },
      ],
      BookingDrDetailPrint: null,
      isLoadPrintBDC: false,
      quickViewListBDC: null,
      isCheckClickBookingDetail: false
    }
  },
  watch: {
    isOpen(val) {
      if (val) {
        if (this.quickViewListBdc) {
          this.searchForm2A.listBDC = [this.quickViewListBdc.toString()]
          this.getDetailBooking2A()
        }

        setTimeout(() => {
          this.$refs.listBDC.$refs.search.focus()
        }, 10)
      } else {
        this.searchForm2A = {
          keyWord: null,
          bookingCode: null,
          openDate: this.getUTC('start', 'day'),
          groupName: null,
          listBDC: [],
          listLocker: [],
          listBagtag: []
        }
        const removeIframe = document.querySelectorAll('iframe')
        if (removeIframe.length > 0) {
          removeIframe.forEach(x => {
            document.body.removeChild(x)
          })
        }
      }
      this.$emit('is-open', { ComponentId: 'sidebar-detail-booking-driving-range', IsActive: val })
    },
    searchForm2A: {
      handler(val) {
        this.getDetailBooking2A()
      },
      deep: true,
    },
    bookingDrId(val) {
      // Nếu có bookingDrDetailId thì ko cần gọi list nữa, tránh gọi 2 lần 2 api
      if (!this.bookingDrDetailId) {
        this.getDetailBooking2A()
      }
    },
    bookingDrDetailId(val) {
      // Sau khi add new bookingDrDetailId đã bị set về null
      if (val) {
        this.getDetailBooking2A()
      }
    },
    dataDetail2A: {
      handler(val) {
        // this.dataChecked = []
        // val.forEach(x => {
        //   x.BookingDr.forEach(y => {
        //     y.BookingDrdetail.forEach(z => {
        //       if (z.Checked) {
        //         this.dataChecked.push(z.Id)
        //       }
        //     })
        //   })
        // })

      },
      deep: true,
    },
    dataDetail2B: {
      handler(val) {
        // reset dataQuickUpdate
        this.dataQuickUpdate = {
          Id: null,
          BagtagCode: null,
          LockerCode: null,
          OpenDate: null,
          GolfClassId: null,
          Note: null,
          BookingDrDetailCourse: [],
        }

        // Cập nhật dataQuickUpdate
        this.dataQuickUpdate.Id = val.BookingDrDetail.Id
        this.dataQuickUpdate.BagtagCode = val.BookingDrDetail.BagtagCode
        this.dataQuickUpdate.LockerCode = val.BookingDrDetail.LockerCode
        this.dataQuickUpdate.OpenDate = val.BookingDrDetail.OpenDate
        this.dataQuickUpdate.GolfClassId = val.BookingDrDetail.Member.GolfClassId
        this.dataQuickUpdate.Note = val.BookingDrDetail.Note
        val.BookingDrDetail.BookingDrDetailCourse.forEach(x => {
          this.dataQuickUpdate.BookingDrDetailCourse.push(x.Course.Id)
        })
      },
      deep: true,
    },
    // addMember: {
    //   handler(val) {
    //     clearTimeout(this.timeout)
    //     this.timeout = setTimeout(() => {
    //       this.getListMember_RME01(val)
    //     }, 500)
    //   },
    //   deep: true,
    // },
    isRemove(val) {
      if (!val) {
        this.listMember = []
      }
    },
  },
  created() {
    commonServices
      .getCommon({ KeyGroup: 'SEX' })
      .then(res => {
        if (res) {
          this.listSex = res.Data.filter(x => x.LangId == this.currentLang)
        }
      })
  },
  mounted() {
    this.getCountries_RNA01()
    this.getCompanies_RCL01()
  },
  methods: {
    async getListMember_RME01() {
      if (this.addMember.FullName || this.addMember.CardCode || this.addMember.Email || this.addMember.PhoneNumber) {
        const body = {
          MemberName: this.addMember.FullName ? this.addMember.FullName : '',
          CardCode: this.addMember.CardCode,
          MemberType: null,
          MemberCardId: null,
          Email: this.addMember.Email,
          PhoneNumber: this.addMember.PhoneNumber,
        }

        const response = await members.getListMember(body)
        if (response.Status === '200') {
          this.listMember = response?.Data?.Member
        }
      } else {
        this.listMember = []
      }
    },
    addMemberToDetailBooking_UBKD9(item) {
      const body = {
        BookingDrDetail: {
          Id: this.dataDetail2B.BookingDrDetail.Id,
          MemberId: item.Id,
        },
      }
      bookingDrivingRange.api_UBKD9(body).then(res => {
        this.showResToast(res)
        this.listMember = []
        this.isRemove = false
        this.addMember = {
          FullName: null,
          FirstName: null,
          LastName: null,
          PhoneNumber: null,
          Email: null,
          SexCode: 'SEX_M',
          MemberCode: null,
        }
        if (res.Status == 200) {
          this.getDetailBooking2A()
          this.$emit('event', { type: 'after-add-member-to-detail-booking' })
        }
      })
    },
    cancelAddMember() {
      this.listMember = []
      this.isRemove = false
      this.addMember = {
        FullName: null,
        FirstName: null,
        LastName: null,
        PhoneNumber: null,
        Email: null,
        SexCode: 'SEX_M',
        MemberCode: null,
      }
    },
    addNewMemberToDetailBooking_IME01() {
      const body = {
        BookingDetailId: this.dataDetail2B.BookingDrDetail.Id,
        BookingType: 'DRIVINGRANGE',
        Member: {
          FullName: this.addMember.FullName,
          FirstName: this.addMember.FirstName,
          LastName: this.addMember.LastName,
          CardCode: this.addMember.CardCode,
          SexCode: this.addMember.SexCode,
          PhoneNumber: this.addMember.PhoneNumber,
          Email: this.addMember.Email,
        },
      }
      members.createMember(body).then(res => {
        this.showResToast(res)
        this.listMember = []
        this.isRemove = false
        this.addMember = {
          FullName: null,
          FirstName: null,
          LastName: null,
          PhoneNumber: null,
          Email: null,
          SexCode: 'SEX_M',
          MemberCode: null,
        }
        if (res.Status == 200) {
          this.getDetailBooking2A()
          this.$emit('event', { type: 'after-add-new-member-to-detail-booking' })
        }
      })
    },
    Preview() {
      const bookingDrDetailId = []
      this.dataDetail2A.forEach(x => {
        x.BookingDr.forEach(y => {
          y.BookingDrdetail.forEach(z => {
            if (z.Checked) {
              bookingDrDetailId.push(z.Id)
            }
          })
        })
      })

      if (bookingDrDetailId.length > 0) {
        const routeData = this.$router.resolve({
          name: 'bookingDrivingRangePrint',
          query: {
            id: bookingDrDetailId,
          },
        })
        window.open(routeData.href, '_blank')
      } else {
        this.showToast('error', 'golf_booking_please_select_booking')
      }
    },
    handleAddService() {
      this.detailBookingDrId = this.dataDetail2B.BookingDrDetail.Id
      this.canShowAddService = true
      this.$root.$emit('bv::toggle::collapse', 'booking-driving-range-add-service-item', { dataDetail2B: this.dataDetail2B, detailBookingDrId: this.detailBookingDrId })
    },
    async getDetailBooking2A() {
      this.isRemove = false
      this.checkedAll = false
      const data = {
        KeyWord: this.searchForm2A.keyWord,
        BookingDrCode: this.searchForm2A.bookingCode,
        BookingDrId: (!this.bookingDrDetailId) ? this.bookingDrId : null,
        GroupName: this.searchForm2A.groupName,
        BookingDrDetailId: (this.searchForm2A.keyWord || this.searchForm2A.bookingCode || this.searchForm2A.listBDC.length || this.searchForm2A.listLocker.length || this.searchForm2A.listBagtag.length ) ? null : (this.bookingDrDetailId) ? this.bookingDrDetailId : null,
        BDC: (this.searchForm2A.listBDC) ? this.searchForm2A.listBDC : [],
        Locker: (this.searchForm2A.listLocker) ? this.searchForm2A.listLocker : [],
        Bagtag: (this.searchForm2A.listBagtag) ? this.searchForm2A.listBagtag : []
      }

      const response = await bookingDrivingRange.getDetailBooking(data)
      if (response.Status === '200') {
        if (response?.Data?.TeeTime) {
          this.dataDetail2A = response?.Data?.TeeTime
        } else {
          this.dataDetail2A = []
        }

        if (this.dataDetail2A) {
          this.bookingCode = []
          this.dataDetail2A.forEach(item => {
            if (item) {
              item.BookingDr.forEach(item1 => {
                this.bookingCode.push(item1.BookingCode)
              })
            }
          })
        }

        // Nếu tồn tại bookingDrDetailId thì gọi chi tiết
        if (this.bookingDrDetailId && !this.searchForm2A.keyWord && !this.searchForm2A.bookingCode && !this.searchForm2A.listBDC.length && !this.searchForm2A.listLocker.length && !this.searchForm2A.listBagtag.length) {
          await this.getDetailBooking2B(this.bookingDrDetailId)
          return
        }
        // Nếu không tồn tại bookingDrDetailId thì gọi bookingDrDetailId đầu tiên của booking
        if (this.dataDetail2A[0] && this.dataDetail2A[0].BookingDr[0] && this.dataDetail2A[0].BookingDr[0].BookingDrdetail[0] && !this.isCheckClickBookingDetail) {
          const bookingDetailId = this.dataDetail2A[0].BookingDr[0].BookingDrdetail[0].Id

          // bookingDrDetailId thay đổi thì đã gọi lại 2A
          this.setActiveUser({ Id: bookingDetailId })

          // this.bookingDrDetailId = bookingDetailId
          await this.getDetailBooking2B(bookingDetailId)
        } else {
          await this.getDetailBooking2B(this.bookingDrDetailId)
          this.isCheckClickBookingDetail = false
        }
      }
    },
    async getDetailBooking2B(Id) {
      if(!Id) {
        return
      }
      this.isLoading = true
      const resBooking2B = await bookingDrivingRange.getDetailBooking2B({ BookingDrDetailId: Id })
      if (resBooking2B.Status === '200') {
        for (const tee of this.dataDetail2A) {
          for (const booking of tee.BookingDr) {
            for (const BookingDetail of booking.BookingDrdetail) {
              if (BookingDetail.Id === Id) {
                BookingDetail.Checked = true
                break
              }
            }
          }
        }
        this.dataDetail2B = resBooking2B?.Data?.BookingDr
        if (this.dataDetail2B.BookingDrDetail.Member.Id === -1) {
          this.isRemove = true
        } else {
          this.isRemove = false
        }
      }
      this.isLoading = false
    },
    handleEvent(event) {
      // Chuyển sang màn thu ngân
      if (event.type === 'open-sidebar-checkout') {
        // Đóng sidebar mua dịch vụ
        this.$root.$emit('bv::toggle::collapse', 'booking-driving-range-add-service-item')
        // Mở sidebar thu ngân
        this.showCheckoutFormByBookingDetailId()
      }
      if (event.type === 'after-checkin') {
        this.getDetailBooking2A()
        if (event.data.length > 0) {
          this.PrintBDC(event.data)
        }
      }
      // Sau khi checkin thành công
      if (event.type === 'after-update-group-info' || event.type === 'after-checkout' || event.type === 'after-cancel-booking' || event.type === 'after-cancel-booking-detail') {
        // 2A đã bao gồm gọi 2B
        this.getDetailBooking2A()
      }

      // save_success: sau khi save thành công member
      if (event.type === 'close_checkin' || event.type === 'save_success') {
        // reset data
        this.dataCheckIn = []
        this.detailBookingDrId = null
        // Reload lại danh sách booking
        this.getDetailBooking2A(true)
        // reset member data
        this.Member = member_params
      } else if (event.type === 'refresh_booking_detail') {
        this.getDetailBooking2B(this.dataDetail2B.BookingDetail.Id)
      }
    },
    showCheckoutFormByBookingDetailId() {
      // Đóng sidebar chi tiết booking
      this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking-driving-range')

      // Mở sidebar thu ngân
      this.$emit('event', { type: 'open-sidebar-checkout', bookingDetailData: { bookingDetailId: [this.bookingDrDetailId] }, bookingDrId: this.bookingDrId })
    },
    changeModeTo(mode) {
      this.mode = mode
    },
    hideSideBar() {
      this.bookingDrDetailId = null
      this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking-driving-range')
    },
    openModal(id) {
      this.$bvModal.show(id)
    },
    addCustomer() {
      this.hideSideBar()
      this.$root.$emit('bv::toggle::collapse', 'add-new-driving-range-booking')
    },
    viewCheckedInvoice() {
      const bookingDrDetailId = []
      this.dataDetail2A.forEach(x => {
        x.BookingDr.forEach(y => {
          y.BookingDrdetail.forEach(z => {
            if (z.Checked) {
              bookingDrDetailId.push(z.Id)
            }
          })
        })
      })

      // Nếu được tích chọn ít nhất 1 người
      if (bookingDrDetailId.length) {
        // Đóng sidebar chi tiết booking
        this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking-driving-range')

        // Mở sidebar thu ngân
        this.$emit('event', { type: 'open-sidebar-checkout', bookingDetailData: { bookingDetailId: bookingDrDetailId }, bookingDrId: this.bookingDrId })
      } else {
        this.showToast('error', 'golf_booking_please_select_booking')
      }
    },
    setActiveUser(bookingDetail, isClick) {
      this.isCheckClickBookingDetail = isClick
      this.bookingDrDetailId = bookingDetail.Id
      // Cập nhật lại bookingDetailId cho prop để khi load lại ko bị mất trạng thái active người hiện tại trong 2A
      // Không cập nhật prop nếu như đang tìm kiếm, nếu không sẽ lỗi khi xem lại chính detail booking đó sau khi tìm kiếm
      if (!this.KeyWord && !this.groupNameSearch && !this.BookingCode) {
        this.$emit('event', { type: 'update-current-booking-detail-id', bookingDrDetailId: this.bookingDrDetailId })
      }
    },

    getStatusText(status) {
      switch (status) {
        case 'CHECK_IN':
          return 'CheckIn'
        case 'CHECK_OUT':
          return 'CheckOut'
        case 'CANCELLED':
          return 'Cancelled'
        case 'NO_SHOW':
          return 'No-Show'
        default:
          return 'Booked'
      }
    },

    async showMemberForm() {
      if (this.dataDetail2B.BookingDrDetail.Member.Id === -1) {
        this.isCreateMember = true
      } else {
        await this.getDetailMember_RME02(this.dataDetail2B.BookingDrDetail.Member.Id)
        this.isCreateMember = false
      }

      this.detailBookingDrId = this.dataDetail2B.BookingDrDetail.Id

      this.$root.$emit('bv::toggle::collapse', 'createMember')
    },

    async getCountries_RNA01() {
      const response = await members.getCountries({})
      if (response) {
        this.countries = response.Data.Country
      }
    },

    async getCompanies_RCL01() {
      const response = await members.getCompanies({})
      if (response) {
        this.companies = response.Data.Client
      }
    },

    async getDetailMember_RME02(id) {
      this.isCreate = false
      const response = await members.detailMember({
        Member: {
          Id: id,
        },
      })
      if (response.Status === '200') {
        this.Member = response?.Data?.Member
      }
    },
    checkAll(value) {
      this.dataDetail2A.forEach(x => {
        x.Checked = value
      })
      this.checkDate(value)
    },
    checkDate(value) {
      // Kiểm tra các date được chọn hết chưa, các date được chọn hết tức tất cả cũng phải được chọn
      if (!value) {
        this.checkedAll = false
      } else {
        this.checkedAll = true
        this.dataDetail2A.forEach(x => {
          if (!x.Checked) {
            this.checkedAll = false
          }
        })
      }

      this.dataDetail2A.forEach(x => {
        x.BookingDr.forEach(y => {
          y.BookingDrdetail.forEach(z => {
            z.Checked = x.Checked
          })
        })
      })
      this.$forceUpdate()
    },
    checkItem() {
      this.dataDetail2A.forEach(x => {
        x.BookingDr.forEach(y => {
          let checkedItem = 0
          y.BookingDrdetail.forEach(z => {
            if (z.Checked) {
              checkedItem++
            }
          })

          // Nếu số lượng item dc check === số lượng mảng BookingDrdetail thì date all được Checked
          if (checkedItem === y.BookingDrdetail.length) {
            x.Checked = true
          } else {
            x.Checked = false
          }
        })
      })

      let checkedDateItem = 0
      this.dataDetail2A.forEach(x => {
        if (x.Checked) {
          checkedDateItem++
        }
      })

      // Nếu số lượng date dc check === số lượng mảng BookingDr thì checkedAll được Checked
      if (checkedDateItem === this.dataDetail2A.length) {
        this.checkedAll = true
      }

      this.$forceUpdate()
    },
    async openModalCheckIn(singleCheckInId) {
      let checkSale = false
      const dataCheckIn = []
      const data = JSON.parse(JSON.stringify(this.dataDetail2A))
      data.forEach(x => {
        x.BookingDr.forEach(y => {
          if(!y.SaleId) {
            checkSale = true
          }
          y.BookingDrdetail.forEach(z => {
            z.CheckPrint = true
            if (singleCheckInId && singleCheckInId == z.Id) {
              dataCheckIn.push(z)
            } else {
            // Lấy các item dc check và ở trạng thái booked
              if (z.Checked && z.BookingDetailStatus === 'BOOKED') {
                dataCheckIn.push(z)
              }
            }
          })
        })
      })
      if(checkSale && this.isRequriedSale == 1) {
        return this.showToast('error', this.$t('golf_booking_require_sale_befor_checkin'))
      }
      this.dataCheckIn = dataCheckIn
      if (this.dataCheckIn.length) {
        this.$root.$emit('bv::show::modal', 'modal-checkin-dr')
      } else {
        this.showToast('error', this.$t('golf_booking_please_select_person_to_check_in'))
      }
    },
    async openModalCancelBookingDetail(singleCheckInId) {
      const dataCancelBookingDetail = []
      this.dataDetail2A.forEach(x => {
        x.BookingDr.forEach(y => {
          y.BookingDrdetail.forEach(z => {
            if (singleCheckInId && singleCheckInId == z.Id) {
              dataCancelBookingDetail.push(z)
            } else {
            // Lấy các item dc check và ở trạng thái booked
              if (z.Checked && z.BookingDetailStatus === 'BOOKED') {
                dataCancelBookingDetail.push(z)
              }
            }
          })
        })
      })
      this.dataCancelBookingDetail = dataCancelBookingDetail
      if (this.dataCancelBookingDetail.length) {
        this.$root.$emit('bv::show::modal', 'modal-cancel-booking-detail-dr')
      } else {
        this.showToast('error', this.$t('golf_booking_please_select_person_to_cancel'))
      }
    },
    async checkOut_ICID02(isSingleCheckout) {
      const dataCheckOut = []
      // Kiểm tra checkout hàng loạt hay 1 người từ chi tiết
      if (isSingleCheckout) {
        dataCheckOut.push(this.dataDetail2B.BookingDrDetail.Id)
      } else {
        this.dataDetail2A.forEach(x => {
          x.BookingDr.forEach(y => {
            y.BookingDrdetail.forEach(z => {
              // Lấy các item dc check và ở trạng thái booked
              if (z.Checked && z.BookingDetailStatus === 'CHECK_IN') {
                dataCheckOut.push(z.Id)
              }
            })
          })
        })
      }

      if (dataCheckOut.length) {
        const body = {
          BookingDrDetailId: dataCheckOut,
        }
        const response = await bookingDrivingRange.api_ICID02(body)
        this.showResToast(response)
        if (response.Status == 200) {
          this.getDetailBooking2A()
          this.$emit('event', { type: 'after-checkout' })
        }
      } else {
        this.showToast('error', this.$t('golf_booking_please_select_person_to_check_out'))
      }
    },
    async cancelBooking() {
      if (this.dataCheckIn.length) {
        this.$bvModal.show('modal-cancel-booking-dr')
      } else {
        this.showToast('error', 'golf_common_error')
      }
    },
    async quickUpdate_UBKD02() {
      this.isLoading = true

      const temp = []
      if (this.dataQuickUpdate.BookingDrDetailCourse) {
        this.dataQuickUpdate.BookingDrDetailCourse.forEach(x => {
          temp.push({
            CourseId: x,
          })
        })
      }

      const body = {
        BookingDrDetail: {
          Id: this.dataQuickUpdate.Id,
          BagtagCode: this.dataQuickUpdate.BagtagCode ? this.dataQuickUpdate.BagtagCode : null,
          LockerCode: this.dataQuickUpdate.LockerCode ? this.dataQuickUpdate.LockerCode : null,
          OpenDate: this.dataQuickUpdate.OpenDate ? this.dataQuickUpdate.OpenDate : null,
          GolfClassId: this.dataQuickUpdate.GolfClassId ? this.dataQuickUpdate.GolfClassId : null,
          Note: this.dataQuickUpdate.Note ? this.dataQuickUpdate.Note : null,
          BookingDrDetailCourse: temp,
        },
      }

      const response = await bookingDrivingRange.api_UBKD02(body)
      this.showResToast(response)
      if (response.Status === '200') {
        this.$emit('event', { type: 'after-update-player-info' })
        // Trong 2A đã gọi 2B
        this.getDetailBooking2A()
      }
      this.isLoading = false
    },
    onTriggeredEventHandler(payload) {
      // if (payload.key === F4) {
      //   this.hideSideBar()
      // }
      if (payload.evt.ctrlKey && payload.key === F) {
        this.$refs.listBDC.$refs.search.focus()
      }
      if (payload.evt.ctrlKey && payload.key === A) {
        this.checkedAll = !this.checkedAll
        this.checkAll(this.checkedAll)
      }
      if (payload.evt.ctrlKey && payload.key === S) {
        this.addNewMemberToDetailBooking_IME01()
      }
      if (payload.evt.ctrlKey && payload.key === P) {
        this.PreviewPrintBDC()
      }
      if (payload.key === F1) {
        if(this.isRemove && this.listMember.length > 0) {
          this.addMemberToDetailBooking_UBKD9(this.listMember[0])
        } else {
          this.isRemove = !this.isRemove
        }
      }
      if (payload.key === F2) {
        if(this.isRemove && this.listMember.length > 0) {
          if(this.listMember.length > 1) {
            this.addMemberToDetailBooking_UBKD9(this.listMember[1])
          }
        } else {
          if (this.checkedAll) {
            this.openModalCheckIn()
          } else if (this.dataDetail2B.BookingDrDetail.BookingDetailStatus === 'BOOKED') {
            this.openModalCheckIn(this.dataDetail2B.BookingDrDetail.Id)
          } else {
            this.showToast('error', 'Golfer đã check in. Vui lòng thử lại')
          }
        }
      }
      if (payload.key === F3) {
        if(this.isRemove && this.listMember.length > 0) {
          if(this.listMember.length > 2) {
            this.addMemberToDetailBooking_UBKD9(this.listMember[2])
          }
        } else {
          const dataCheckOut = []
          this.dataDetail2A.forEach(x => {
            x.BookingDr.forEach(y => {
              y.BookingDrdetail.forEach(z => {
                // Lấy các item dc check và ở trạng thái booked
                if (z.Checked && z.BookingDetailStatus === 'CHECK_IN') {
                  dataCheckOut.push(z.Id)
                }
              })
            })
          })
          if (dataCheckOut.length) {
            this.checkOut_ICID02()
          } else {
            this.checkOut_ICID02(true)
          }
        } 
      }
      if (payload.key === F4) {
        if(this.isRemove && this.listMember.length > 0) {
          if(this.listMember.length > 3) {
            this.addMemberToDetailBooking_UBKD9(this.listMember[3])
          }
        } else {
          const bookingDrDetailId = []
          this.dataDetail2A.forEach(x => {
            x.BookingDr.forEach(y => {
              y.BookingDrdetail.forEach(z => {
                if (z.Checked) {
                  bookingDrDetailId.push(z.Id)
                }
              })
            })
          })
          if (bookingDrDetailId.length > 0) {
            this.viewCheckedInvoice()
          } else {
            this.showCheckoutFormByBookingDetailId()
          }
        }
      }
      if (payload.key === F5) {
        if(this.isRemove && this.listMember.length > 4) {
          this.addMemberToDetailBooking_UBKD9(this.listMember[4])
        }
      }
      if (payload.key === F6) {
        if(this.isRemove && this.listMember.length > 5) {
          this.addMemberToDetailBooking_UBKD9(this.listMember[5])
        }
      }
      if (payload.key === F7) {
        if(this.isRemove && this.listMember.length > 6) {
          this.addMemberToDetailBooking_UBKD9(this.listMember[6])
        }
      }
      if (payload.key === F8) {
        if(this.isRemove && this.listMember.length > 7) {
          this.addMemberToDetailBooking_UBKD9(this.listMember[7])
        }
      }
      if (payload.key === F9) {
        if(this.isRemove && this.listMember.length > 8) {
          this.addMemberToDetailBooking_UBKD9(this.listMember[8])
        }
      }
      if (payload.key === F10) {
        if(this.isRemove && this.listMember.length > 9) {
          this.addMemberToDetailBooking_UBKD9(this.listMember[9])
        }
      }
      if (payload.key === F11) {
        if(this.isRemove && this.listMember.length > 10) {
          this.addMemberToDetailBooking_UBKD9(this.listMember[10])
        }
      }
      if (payload.key === F12) {
        if(this.listMember.length > 11) {
          this.addMemberToDetailBooking_UBKD9(this.listMember[11])
        }
      }
    },
    SidebarOpenOrCloseHandler(val) {
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    PreviewPrintBDC() {
      const bookingDrDetailId = []
      this.dataDetail2A.forEach(x => {
        x.BookingDr.forEach(y => {
          y.BookingDrdetail.forEach(z => {
            if (z.Checked) {
              bookingDrDetailId.push(z.Id)
            }
          })
        })
      })

      if (bookingDrDetailId.length > 0) {
        this.PrintBDC(bookingDrDetailId)
      } else {
        this.showToast('error', 'golf_booking_please_select_booking')
      }
    },
    PrintBDC(data) {
      this.isLoadPrintBDC = true
      const boby = {
        BookingDrDetailId: data,
      }
      bookingPrint
        .getListDetailBookingDr(boby)
        .then(res => {
          if (res) {
            this.BookingDrDetailPrint = res.Data.BookingDrDetail
            setTimeout(() => {
              const divContents = document.getElementById('print-tem').innerHTML
              const data = `<html><head><title>ezCloud_ezGolf</title> <meta charset="UTF-8">
              </head><body style="padding: 0px;margin: 0px;">
              <style >  @media print { @page { margin:0; } *{font-family:  Arial, Helvetica, sans-serif ;} p{margin:0;}} </style>
              ${divContents}
              </body></html>
              `
              const newBlob = new Blob([data], {
                type: 'text/html',
              })
              const fileLink = window.URL.createObjectURL(newBlob)
              const iframe = document.createElement('iframe')
              iframe.src = fileLink
              iframe.style.display = 'none'
              iframe.onload = () => {
                iframe.contentWindow.focus()
                iframe.contentWindow.print()
              }
              document.body.appendChild(iframe)
              this.isLoadPrintBDC = false
            }, 2000)
          }
        })
    },
    CopyText(text) {
      document.body.insertAdjacentHTML('beforeend', `<div id="copy" contenteditable>${text}</div>`)
      document.getElementById('copy').focus()
      document.execCommand('selectAll')
      document.execCommand('copy')
      document.getElementById('copy').remove()
    },
    async UndoCheckOut(Id) {
      const body = {
        BookingDrDetailId: [Id],
      }
      await bookingDrivingRange.api_ICID03(body).then(res => {
        this.getDetailBooking2A()
      })
    },
    openSidebarHistory() {
      this.$refs.History.open()
    }
  },
}

/* eslint-disable radix */
import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import { exportDataGrid as exportDataGridToPdf } from 'devextreme/pdf_exporter'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import ShortKey from '@/views/modules/ultils/ShortKey'
import DxButton from 'devextreme-vue/button'
import 'devextreme/dist/css/dx.light.css'
import { booking } from '@/api/booking'
import {
  F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F,
} from '@/api/constant.js'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'

import { golfClassSetting } from '@/api/golf-class-setting'
import { drivingRangeSetting } from '@/api/driving-range-setting'
import { bookingDrivingRange } from '@/api/booking-driving-range'
import AddNewBooking from './AddNewBooking/AddNewBooking.vue'

import SidebarDetailBooking from './SidebarDetailBooking/SidebarDetailBooking.vue'

import CheckIn from './modal/CheckIn.vue'
import SidebarCheckout from './SidebarCheckout/SidebarCheckout.vue'
import Payment from './Payment/Payment.vue'
import DeletePayment from './Payment/DeletePayment.vue'
import DeletePaymentDetail from './Payment/DeletePaymentDetail.vue'

const dataGridRef = 'tablePackage'

export default {
  name: 'BookingDrivingRange',
  components: {
    SidebarDetailBooking,
    SidebarCheckout,
    CheckIn,
    Payment,
    DeletePayment,
    DeletePaymentDetail,
    AddNewBooking,
    DxButton,
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    ShortKey,
  },
  data() {
    return {
      listDrivingRange: [],
      listDrivingRangeActive: [],
      listBookingDrivingRange: [],
      configTable: [
        {
          caption: 'BDC',
          field: 'BDC',
          isShow: true,
          alignment: 'left',
          width: '10%',
        },
        {
          caption: this.$t('golf_boongking_dr_booking_code'),
          field: 'BookingCode',
          isShow: true,
          alignment: 'left',
          width: '10%',
        },
        {
          caption: this.$t('golf_boongking_dr_bagtag'),
          field: 'BagtagCode',
          isShow: true,
          alignment: 'left',
          width: '10%',
          class: 'text-truncate',
        },
        {
          caption: 'Locker',
          field: 'LockerCode',
          isShow: true,
          alignment: 'left',
          width: '10%',
          class: 'text-truncate',
        },
        {
          caption: this.$t('golf_boongking_dr_name'),
          isShow: true,
          field: 'Member.FullName',
          alignment: 'left',
          // cellTemplate: 'booking-name',
          width: '15%',
          class: 'text-truncate',
        },
        {
          caption: this.$t('golf_boongking_dr_golf_class'),
          isShow: true,
          field: 'Member.GolfClassName',
          alignment: 'left',
          cellTemplate: 'golf-class',
          width: '10%',
          class: 'text-truncate',
        },
        {
          caption: this.$t('golf_boongking_dr_note'),
          field: 'Note',
          isShow: true,
          alignment: 'left',
          width: '20%',
          class: 'text-truncate',
        },
        {
          caption: this.$t('golf_boongking_dr_checkin'),
          field: 'CheckInOpenDate',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'check-in',
          width: '15%',
        },
        {
          caption: this.$t('golf_boongking_dr_checkout'),
          field: 'CheckOutOpenDate',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'check-out',
          width: '15%',
        },
        {
          caption: this.$t('golf_boongking_dr_amout'),
          field: 'Amount',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'amount',
          width: '10%',
        },
        {
          caption: this.$t('golf_boongking_dr_invoice'),
          field: 'PaymentCode',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'payment-code',
          width: '15%',
        },
        // {
        //   caption: '',
        //   field: 'action',
        //   isShow: true,
        //   cellTemplate: 'action',
        //   alignment: 'left',
        // },
      ],
      dataGridRef: 'tablePackage',
      search: {
        KeyWord: null,
        CourseId: [],
        Date: this.getUTC('start', 'day'), // this.dayjs().local().startOf('day'),
        BookingCode: null,
        listBDC: [],
        listBagtag: [],
        listStatus: ['BOOKED','CHECK_IN','CHECK_OUT']
      },
      colorBoookingStatus: [
        {
          value: 'BOOKED',
          color: 'rgba(0, 207, 232, 0.12)',
        },
        {
          value: 'CHECK_IN',
          color: 'rgba(234, 84, 85, 0.12)',
        },
        {
          value: 'CHECK_OUT',
          color: 'rgba(136, 91, 234, 0.12)',
        },
        {
          value: 'CANCELLED',
          color: 'rgba(161, 164, 156, 0.12)',
        },
      ],
      listItemPerPage: [10, 20, 50],
      itemPerPage: 10,
      bookingDrId: null,
      bookingDrDetailId: null,
      BookingDetail: null,
      bookingDetailData: null,
      closePay: false,
      paymentId: null,
      deletePaymentId: null,
      deletePaymentDetailId: null,
      dataCheckIn: [],
      listGolfClass: [],
      ShortCutKey: [F1, F2, F3, F4, F5, F6, F7, F8, F9, F10, F11, F12, A, F],
      allChildComponentClosed: [
        {
          ComponentId: 'add-new-driving-range-booking',
          IsActive: false,
        },
        {
          ComponentId: 'sidebar-detail-booking-driving-range',
          IsActive: false,
        },
        {
          ComponentId: 'sidebar-checkout-dr',
          IsActive: false,
        },
        {
          ComponentId: 'modal-payment-dr',
          IsActive: false,
        },
      ],
      ROLE_OF_CASHIER: null,
      ROLE_OF_SALE: null,
      listUserOfSale: [],
      listUserOfCashier: null,
      DefaultSearch: null,
      windowHeight: 0,
      heightTable: 400,
      listBookingDRStatusStatistic: null,
      optionBookingStatus: [
        {
            value:'BOOKED',
            label:'Booked'
        },
        {
            value:'CHECK_IN',
            label:'Check in'
        },
        {
            value:'CHECK_OUT',
            label:'Check out'
        },
        {
            value:'CANCELLED',
            label:'Hủy'
        }
      ],
      IsRequriedSale: null
    }
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  created() {
    if (this.$route.query.bdc) {
      this.quickViewListBDC = this.$route.query.bdc
      this.bookingDrId = null
      this.bookingDrDetailId = null
      this.quickViewListBDC = this.$route.query.bdc

      setTimeout(() => {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking-driving-range')
      }, 1)
    }

    this.getRoleId()
    this.init_RBKD01()

    // Lấy danh sách golf class
    const body = {
      KeyWord: null,
      CourseId: [],
    }
    golfClassSetting
      .getListGolfClass(body)
      .then(res => {
        if (res.Status === '200') {
          this.listGolfClass = res.Data.GolfClass
        } else {
          this.showResToast(res)
        }
      })
  },
  watch: {
    ROLE_OF_CASHIER(value) {
      this.getCashierRole(value)
    },
    ROLE_OF_SALE(value) {
      this.getCashierRole(value)
    },
    'search.listBDC': function (val) {
      this.$refs.listBDC.$refs.search.focus()
    },
    windowHeight(value) {
      if(value > 600 ) {
        this.heightTable = value - 315
      }
    }
  },
  mounted() {
    this.windowHeight = window.innerHeight
  },
  methods: {
    close(val) {
      this.closePay = !this.closePay
    },
    async init_RBKD01() {
      this.isLoading = true
      // Lấy list sân tập
      drivingRangeSetting
        .getListDrivingRange()
        .then(res => {
          if (res.Status === '200') {
            this.listDrivingRange = res.Data.DrivingRange
            this.listDrivingRangeActive = this.listDrivingRange.filter(x => x.InActive === false)
          } else {
            this.showResToast(res)
          }
        })

      // Lấy list booking
      this.getListBookingDrivingRange_RBKD01_AC1()
      this.isLoading = false
    },
    async getListBookingDrivingRange_RBKD01_AC1() {
      const body = {
        BookingCode: this.search.BookingCode,
        KeyWord: this.search.KeyWord,
        CourseId: this.search.CourseId,
        OpenDate: this.search.Date,
        BDC: this.search.listBDC,
        BookingStatus: this.search.listStatus
      }
      await bookingDrivingRange
        .getListBookingDrivingRange(body)
        .then(res => {
          if (res.Status === '200') {
            this.listBookingDrivingRange = res.Data.BookingDrDetail
            this.listBookingDRStatusStatistic = res.Data.BookingDRStatusStatistic
          } else {
            this.showResToast(res)
          }
        })
    },
    cellPrepared(e) {
      if (e.rowType !== 'data') { return }
      if (e.column.dataField == 'BookingCode') {
        e.cellElement.style.backgroundColor = this.colorBoookingStatus.find(x => x.value == e.data.BookingDetailStatus).color
      }
    },
    viewDetailBooking(item, force) {
      // Xem từ row data hoặc từ nut 3 chấm ở action
      if (item.column && item.column.name != 'action' && item.data.Id || force) {
        this.handleEvent({ type: 'view-detail-booking', bookingDrId: item.data.BookingDrId, bookingDrDetailId: item.data.Id })
      }
    },
    handleEvent(result) {
      if (result.type) {
        if (result.type === 'after-add-member-to-detail-booking' || result.type === 'after-add-new-member-to-detail-booking' || result.type === 'after-checkout' || result.type === 'after-checkin' || result.type === 'after-update-player-info' || result.type === 'after-cancel-booking' || result.type === 'after-checkout-from-sidebar-checkout' || result.type === 'after-issue-invoice') {
          this.getListBookingDrivingRange_RBKD01_AC1()
        }

        // Cập nhật lại booking detail id đang active để khi reload lại không bị mất người đang xem.
        if (result.type === 'update-current-booking-detail-id') {
          this.bookingDrDetailId = result.bookingDrDetailId
        }

        if (result.type === 'after-add-new-booking') {
          // Load lại danh sách booking
          this.getListBookingDrivingRange_RBKD01_AC1()

          // Gán bookingDrId để xem chi tiết booking vừa tạo
          if (result.bookingDrId) {
            this.bookingDrId = result.bookingDrId
          }

          // Nếu tồn tại bookingDrId thì set bookingDrDetailId về null để mở popup
          if (result.bookingDrId) {
            this.bookingDrDetailId = null
          }

          this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking-driving-range')
        }

        if (result.type === 'after-add-more-customer-from-detail-booking') {
          // BookingDrId không đổi, load lại danh sách 2A để lấy booking mới được thêm vào.
          this.$refs.sidebarDetailBooking.getDetailBooking2A()
          // Load lại danh sách booking
          this.getListBookingDrivingRange_RBKD01_AC1()
          // Mở lại chi tiết bookingdrid vừa xem ( trước khi thêm khách )
          this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking-driving-range')
        }

        if (result.type === 'view-detail-booking') {
          if (result.bookingDrId) {
            this.bookingDrId = result.bookingDrId
          }
          if (result.bookingDrDetailId) {
            this.bookingDrDetailId = result.bookingDrDetailId
          }
          this.$root.$emit('bv::toggle::collapse', 'sidebar-detail-booking-driving-range')
        }

        if (result.type === 'open-modal-payment') {
          this.paymentId = result.paymentId
          this.$root.$emit('bv::show::modal', 'modal-payment-dr')
          return
        }

        if (result.type === 'open-sidebar-checkout') {
          this.bookingDetailData = result.bookingDetailData
          this.bookingDrId = result.bookingDrId
          this.$root.$emit('bv::toggle::collapse', 'sidebar-checkout-dr')
          return
        }

        if (result.type === 'open-modal-delete-payment') {
          this.deletePaymentId = result.paymentId
          this.$root.$emit('bv::show::modal', 'modal-delete-payment-dr')
          return
        }

        if (result.type === 'open-modal-delete-payment-detail') {
          this.deletePaymentDetailId = result.paymentDetailId
          this.$root.$emit('bv::show::modal', 'modal-delete-payment-detail-dr')
        }

        if (result.type === 'after-delete-payment' || result.type === 'after-add-new-payment-detail' || result.type === 'after-delete-payment-detail') {
          // Load lại danh sách các hoá đơn tạm tính
          this.$refs.sidebarCheckout.getTempInvoice_RPM03()
          // Load lại D2D để lấy các lần thanh toán
          this.$refs.sidebarCheckout.getDetailBooking_RBKD2D()
          // Nếu là xoá các thanh toán cũng load lại hoá đơn tạm tính
          if (result.type === 'after-delete-payment-detail') {
            // Load lại danh sách các lần thanh toán của hoá đơn tạm tính
            this.$refs.modalPayment.getDetailPayment_RPM04()
          }
        }
      }
    },
    cancelBooking() {

    },
    addNewBooking() {
      this.bookingDrId = null
      this.$refs.addNewBooking.open()
    },
    exportPDF() {
      const pdfDoc = new jsPDF('p', 'pt', 'a4')
      exportDataGridToPdf({
        jsPDFDocument: pdfDoc,
        component: this.dataGrid,
      }).then(() => {
        pdfDoc.setFontSize(12)
        const pageCount = pdfDoc.internal.getNumberOfPages()
        for (let i = 1; i <= pageCount; i++) {
          pdfDoc.setPage(i)
          const { pageSize } = pdfDoc.internal
          const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth()
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
          const header = 'Report 2021'
          const footer = `Page ${i} of ${pageCount}`
          const logo = 'iVBORw0KGgoAAAANSUhEUgAAAHUAAAAcCAMAAABoIQAcAAAAw1BMVEUAAAAQSqEQR54QSZ8QSZ8Aod0AoNwAn9sRR50RRZr/7Un/808RS58TRpr/6UMMX64RS6AbM4H+yCEcMoKJglj/zSj7xyQRSp8AoNwRR5wRSJ0RSZ4VQZMUQpYSRpoTRJj/6UP/8EsWPpAAmNgXOosaNIQTRJf/8k3/5T8Andr+3jkAk9T/7kj/4jz+2jQAjtL/7Eb+1zABic4Hdr/+0y3+0CoDhMoEfcVQYnb+zCXHv1bFuVAJZrRUYXOLjGHErkfEqUEir4bvAAAAF3RSTlMAf0D+v3+/QP7+QL+/v3/+v7+/f39/QKzJvtMAAAJISURBVEjH7ZXpctowFIUtWTZLm3TvxRbGAQJJmjS00LAk3d7/qXoXScjTYQI/yo8mZ7B0pdH4m3NkieRZT0hatUsL8FIlx5M+KYqytDYFAJ0cSbogaMlQ9JscRSedAWFtCYJ9nRxBespU8poC/cAk/1y6mnYKVBv3FUhIPgirldIHL6iqeiAJI5WQ+Oz1SSkFRinsAVSyW9GC00+3X7/dffiIUxE1BS/zONOv3Jvavbz8zNjN2/f9alr7fQWUI6vHYmUiLdyX2j07Qypi7zabdZ+xRbuNR4eQ/kkayrJWc8LnoaMAlXJ03kaNA+40L+jefEGsmP2N0Kqu/cfkI361WGQRIu+hcuRixyVbbVrhKamwC7PGR3c6vCEqY9d9xNYccEg4Xbh3B6NhHKjBX6AKlMqYasLscOjN3v4ajYjaGXDCVrKd97xcqlxlbtjiUUwN79eMjqg0Nq68YCx/UGukVtWUzJbtUhJeXvWCPDVnwxmnzf3fVIncNKjceCph2ezP8xEnzEcHzaY2heX4aovNo4CFmtPkbqraSX2BWM744Ryp5NWd1xTNrq7H48hsSzLNM5LL15HgMGr3QjJ+mCA1JFyQVVhdM7VpVrrtppL8X6JuUmEnFc0S9n6CVEo4UK1dzZpUYeRsNs9dhRKzRim+m6iS1mDDVKWoiUvCDu9/TCIq3/7WLuazGVEjrMQqokIUDqL3BtpPiHmkO5vRVffuDTKZili/r9+Xc6I2zWbhxGbxLYHSYpcqgw6FoLY7bZT2JWD5rP9SfwAlY2dKPlPjkQAAAABJRU5ErkJggg=='

          // Add logo
          pdfDoc.addImage(logo, 'JPEG', 40, 10, 50, 25)

          // Header
          pdfDoc.text(header, 120, 20, { baseline: 'top' })

          // Footer
          pdfDoc.text(footer, pageWidth / 2 - (pdfDoc.getTextWidth(footer) / 2), pageHeight - 15, { baseline: 'bottom' })
        }
      }).then(() => {
        pdfDoc.save('Customers.pdf')
      })
    },
    exportExcel() {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Employees')
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = 'Country Area, Population, and GDP Structure'
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)

        footerRow.getCell(1).value = 'www.wikipedia.org'
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx')
        })
      })
    },
    async getFullBDCShortCutKey(BDCIndex) {
      const body = {
        BookingCode: this.search.BookingCode,
        KeyWord: this.search.KeyWord,
        CourseId: this.search.CourseId,
        OpenDate: this.search.Date,
        BDC: [BDCIndex],
      }
      await bookingDrivingRange
        .getListBookingDrivingRange(body)
        .then(res => {
          if (res.Data.BookingDrDetail.length > 0) {
            this.handleEvent({ type: 'view-detail-booking', bookingDrId: res.Data.BookingDrDetail[0].BookingDrId, bookingDrDetailId: res.Data.BookingDrDetail[0].Id })
          } else {
            this.showToast('error', `Không tìm thấy Golfer phù hợp với BDC: ${BDCIndex}`)
          }
        })
    },
    onTriggeredEventHandler(payload) {
      if (payload.key === F1) {
        this.addNewBooking()
      }
      if (payload.key === F2) {
        if (this.search.listBDC.length > 0) {
          console.log(this.search.listBDC[this.search.listBDC.length - 1])
          this.getFullBDCShortCutKey(this.search.listBDC[this.search.listBDC.length - 1])
        } else {
          this.showToast('error', 'Vui lòng nhập mã BDC')
        }
      }
      if (payload.key === F5) {
        this.getListBookingDrivingRange_RBKD01_AC1()
      }
      if (payload.evt.ctrlKey && payload.key === F) {
        if (this.DefaultSearch === 'BAGTAG') {
          //this.$refs.inputSearch.$refs.input.focus()
        } else if (this.DefaultSearch === 'BDC') {
          this.$refs.listBDC.$refs.search.focus()
        }
      }
    },
    SidebarOpenOrCloseHandler(val) {
      if(!val.IsActive && val.ComponentId == 'sidebar-checkout-dr' || val.ComponentId == 'sidebar-detail-booking-driving-range') {
        this.getListBookingDrivingRange_RBKD01_AC1()
      }
      this.allChildComponentClosed.find(x => {
        if (x.ComponentId === val.ComponentId) {
          x.IsActive = val.IsActive
        }
      })
    },
    async getRoleId() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(res => {
        if (res.Status === '200') {
          this.DefaultSearch = res?.Data.find(x => x.KeyCode === 'BOOKING_DEFAULT_SEARCH').KeyValue
          this.ROLE_OF_CASHIER = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_CASHIER').KeyValue)
          this.ROLE_OF_SALE = Number(res?.Data.find(item => item.KeyCode === 'ROLE_OF_SALE').KeyValue)
          this.IsRequriedSale = res?.Data.find(x => x.KeyCode === 'BOOKING_DR_SALE_REQUIRED').KeyValue
          setTimeout(() => {
            if (this.DefaultSearch === 'BAGTAG') {
              //this.$refs.inputSearch.$refs.input.focus()
            } else if (this.DefaultSearch === 'BDC') {
              this.$refs.listBDC.$refs.search.focus()
            }
          }, 10)
        }
      })
    },
    async getCashierRole(Id) {
      const body = {
        RoleId: [Id],
      }
      await booking.api_RCS01(body).then(res => {
        if (res.Status === '200') {
          if (Id === this.ROLE_OF_CASHIER) {
            this.listUserOfCashier = res?.Data?.Cashier.map(x => ({
              id: x.Id,
              label: x.CashierName,
            }))
          }
        }
        if (Id === this.ROLE_OF_SALE) {
          this.listUserOfSale = res?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
    getStatusText(status) {
      switch (status) {
        case 'CHECK_IN':
          return 'CheckIn'
        case 'CHECK_OUT':
          return 'CheckOut'
        case 'CANCELLED':
          return 'Cancelled'
        case 'NOSHOW':
          return 'No-Show'
        default:
          return 'Booked'
      }
    },
  },
}
